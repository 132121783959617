<template>
  <header class="header">
    <div class="header-inner">
      <img src="../../assets/images/bbs/yunpan_logo.png" class="logo" />
      <span>影视/动漫</span>
      <span>电子书</span>
      <span>学习教育</span>
      <span>图片</span>
    </div>
  </header>
</template>
<script>
export default {
  data() {
    return {
      keyword: "",
    }
  },
  mounted() {},
  methods: {
    submit() {
      return this.$message.warning("登录后才可以搜索")
    },
    async showLogin() {
      return this.$message.warning("当前尚未开放注册")
    },
  },
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_base.scss";
@import "@/assets/scss/_var.scss";
.header {
  padding: 0.5rem 1rem;
  background-color: #343a40;
  z-index: 10;
  position: sticky;
  top: 0;
  box-shadow: 0 0 1px 1px rgba(28, 28, 32, 0.05),
    0 8px 24px rgba(28, 28, 32, 0.06);
  .header-inner {
    width: 750px;
    margin: 0 auto;
    display: flex;
    max-width: 100%;
    min-height: 36px;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    text-align: center;
    .logo {
      height: 50px;
      margin-right: 10px;
    }
    span {
      margin: 0 10px;
    }
  }
}
@media (max-width: 700px) {
  .header {
    padding: 10px;
    .header-inner {
      .logo {
        height: 30px;
      }
    }
  }
}
</style>
